import { defineStore } from "pinia";

export const useErrorStore = defineStore("errors", {
    state: () => {
        return { errors: [], formErrors: null };
    },
    getters: {
        getFormError: (state) => {
            return (name: string) => {
                if (state.formErrors && state.formErrors[name]) {
                    return state.formErrors[name][0];
                }
                return null;
            };
        },
    },
    actions: {
        clearFormErrors() {
            this.formErrors = null;
        },
        setFormErrors(errors: null) {
            this.formErrors = errors;
        },
    },
});