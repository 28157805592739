<script lang="ts">
import {defineComponent} from 'vue'
definePageMeta({
  middleware: ["guest"]
})
export default defineComponent({
  name: "[token]",
  data() {
    return {
      loading: false,
      form: {
        token: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
    }
  },
  mounted() {
    this.form.token = this.$route.params.token
    this.form.email = this.$route.query.email
  },
  methods: {
    resetPassword() {
      $api('/auth/reset-password', {
        method: 'POST',
        body: this.form
      }).then(async () => {
        navigateTo('/login')
      }).finally(() => {
        this.loading = false
      })
    }
  }
})
</script>

<template>
  <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <NuxtImg format="webp" class="mx-auto h-10 w-auto" src="/img/logo_icon_red.png" alt="The MCModels pictorial logo mark" />
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-gray-100">Reset your password</h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form class="space-y-6" @submit.prevent="resetPassword">

        <FieldsPassword
            name="password"
            v-model="form.password"
            label="New password"
            autocomplete="password"
            required/>

        <FieldsPassword
            name="password_confirmation"
            v-model="form.password_confirmation"
            label="Confirm new password"
            autocomplete="password_confirmation"
            required/>


        <div>
          <Button type="submit" class="w-full" :loading="loading">Reset password</Button>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>

</style>