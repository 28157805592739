import validate from "/home/ploi/mcmodels.net/node_modules/nuxt/dist/pages/runtime/validate.js";
import clear_45errors_45global from "/home/ploi/mcmodels.net/middleware/clearErrors.global.ts";
import manifest_45route_45rule from "/home/ploi/mcmodels.net/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  clear_45errors_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/ploi/mcmodels.net/middleware/auth.ts"),
  guest: () => import("/home/ploi/mcmodels.net/middleware/guest.ts")
}