<script lang="ts">
import { mapState } from 'pinia'
import {defineComponent} from 'vue'
import {useUserStore} from '~/stores/user'
import type {User} from "~/types/models";
import type {UserResponse} from "~/types/responses";
import Profile from "~/layouts/profile.vue";
import DiscordConnectCard from "~/components/profile/DiscordConnectCard.vue";
import {useNotificationStore} from "~/stores/util/notifications";

definePageMeta({
  middleware: ["auth"],
})
export default defineComponent({
  name: "index",
  components: {DiscordConnectCard, Profile},
  data() {
    return {
      loading: false,
      profile: {
        first_name: '',
        last_name: '',
        display_name: '',
        email: '',
      },
      invoicing: {
        country: '',
        state: '',
        city: '',
        address: '',
        company_name: '',
        invoice_email: '',
      },
    }
  },
  mounted() {
    this.setUser(this.getUser)
  },
  computed: {
    ...mapState(useUserStore, ['getUser']),
  },
  watch: {
    getUser(newValue) {
      this.setUser(newValue)
    }
  },
  methods: {
    setUser(user: User) {
      if (!user) return
      this.profile = useFilterObject().filterObject(user, ['first_name', 'last_name', 'display_name', 'email'])
      if(user.invoicing_details) {
        this.invoicing = useFilterObject().filterObject(user.invoicing_details, ['country', 'state', 'city', 'address', 'company_name', 'invoice_email'])
      }
    },
    updateUser() {
      this.loading = true
      $api('/user', {
        method: 'PUT',
        body: this.profile
      }).then(async (response: UserResponse) => {
        useUserStore().setUser(response.user)
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    updateInvoicing() {
      this.loading = true
      $api('/user/invoicing-details', {
        method: 'PUT',
        body: this.invoicing
      }).then(async (response) => {
        useUserStore().setUser(response.user)
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    unsubscribe() {
      $api('/user/unsubscribe', {
        method: 'POST',
      }).then(() => {
        useNotificationStore().addNotification({
          message: "Unsubscribed from Emails!",
          type: 'error',
          duration: 5000,
        })
      })
    }
  }

})
</script>

<template>
  <Profile>
    <div class="grid gap-8">
      <form @submit.prevent="updateUser()">
        <Card title="Your profile">
          <div class="grid gap-y-4">
            <div class="sm:grid gap-4 grid-cols-2">
              <FieldsInput name="first_name" label="First name" type="text" v-model="profile.first_name" required/>
              <FieldsInput name="last_name" label="Last name" type="text" v-model="profile.last_name" required/>
            </div>
            <FieldsInput name="display_name" label="Display name" type="text" v-model="profile.display_name"
                         description="This is the name that will appear on your profile and when you submit reviews"/>
            <FieldsInput name="email" label="Email address" type="email" required
                         v-model="profile.email"
            />
          </div>
          <template v-slot:footer>
            <div class="flex justify-end">
              <Button :loading="loading" type="submit">Save</Button>
            </div>
          </template>
        </Card>
      </form>

      <form @submit.prevent="updateInvoicing">
        <Card title="Invoice information" description="This information will be used on invoices and other receipts">
          <div class="grid gap-y-4">
            <FieldsCountrySelect name="country" label="Country" v-model="invoicing.country"/>
            <FieldsInput name="state" label="State or county" type="text" v-model="invoicing.state"/>
            <FieldsInput name="city" label="City" type="text" v-model="invoicing.city"/>
            <FieldsInput name="address" label="Street and house number" type="text" v-model="invoicing.address"/>
            <FieldsInput name="company_name" label="Company name" type="text" v-model="invoicing.company_name"/>
            <FieldsInput name="invoice_email" label="Email address" type="email" v-model="invoicing.invoice_email" description="Use this field if you want the invoice to go to a different email then your account email"/>
          </div>

          <template v-slot:footer>
            <div class="flex justify-end">
              <Button :loading="loading" type="submit">Save</Button>
            </div>
          </template>
        </Card>
      </form>

      <DiscordConnectCard/>

      <Card title="Unsubscribe from Emails"
            description="We will provide notifications of sales, upcoming features, discounts, and product reminders from your wishlist through these emails! If you really want to unsubscribe, click the button below.">

            <Button @click="unsubscribe" :loading="loading">Unsubscribe</Button>

      </Card>
    </div>

  </Profile>

</template>

<style scoped>

</style>