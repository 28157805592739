<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
useFavicon('favicon.ico');
useHead({
  link: [{rel: 'icon', type: 'image/jpeg', href: '/favicon.jpg'}],
  htmlAttrs: {
    lang: 'en'
  },
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk}` : 'MCModels';
  }
})

useSeoMeta({
  ogSiteName: 'MCModels',
  themeColor: '#FF0000',
  author: 'MCModels',
})
</script>