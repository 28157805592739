<script lang="ts">
import {TrashIcon} from '@heroicons/vue/24/solid'
import {defineComponent} from 'vue'
import {useCartStore} from '~/stores/cart'
import {useUserStore} from "~/stores/user";
import {mapState} from "pinia";
import Input from "~/components/fields/Input.vue";
import type {OrderResponse} from "~/types/responses";
import {formatPrice} from "~/utils/formatPrice";
import CheckBox from "~/components/fields/CheckBox.vue";

export default defineComponent({
  setup() {
    definePageMeta({
      middleware: ["auth"],
    })
  },
  name: "cart",
  components: {
    CheckBox,
    Input,
    TrashIcon,
  },
  data() {
    return {
      loading: false,
      invoicing_details: {
        company_name: '',
        invoice_email: '',
        address: '',
        country: '',
        state: '',
        city: '',
        save_invoicing_details: false,
        marketing_consent: false
      }
    }
  },
  mounted() {
    if (this.getUser) {
      this.invoicing_details = useFilterObject().filterObject(this.getUser.invoicing_details, ['company_name', 'invoice_email', 'address', 'country', 'state', 'city'])
    }
  },
  watch: {
    getUser(user) {
      if (user.invoicing_details) {
        this.invoicing_details = useFilterObject().filterObject(user.invoicing_details, ['company_name', 'invoice_email', 'address', 'country', 'state', 'city'])
      }
    }
  },
  computed: {
    ...mapState(useCartStore, ['cart']),
    ...mapState(useCartStore, ['total']),
    ...mapState(useCartStore, ['count']),
    ...mapState(useUserStore, ['getUser']),
  },
  methods: {
    formatPrice,
    submit() {
      if (this.loading) return
      this.loading = true
      $api('/user/orders', {
        method: 'POST',
        body: {
          ...this.invoicing_details
        }
      }).then(async (response: OrderResponse) => {
        if (this.invoicing_details.save_invoicing_details) {
          $api('/user/invoicing-details', {
            method: 'PUT',
            body: this.invoicing_details
          }).then(async (response) => {
            useUserStore().setUser(response.user)
          }).catch(() => {
          })
        }

        this.loading = false
        navigateTo(response.redirect_url, {
          external: true
        })

      }).catch(() => {
        this.loading = false
      })
    }
  }
})
</script>

<template>
  <div>
    <div class="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
      <h2 class="sr-only">Checkout</h2>

      <form @submit.prevent="submit" class="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
        <div>
          <div>
            <h2 class="text-lg font-medium text-gray-900 dark:text-white">Invoicing information</h2>
            <p class="text-sm text-gray-700 dark:text-gray-300">This information is automatically loaded from your
              profile, you can change it down here if you wish to use different details.</p>

            <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
              <FieldsInput label="Company name" name="company_name" class="sm:col-span-2"
                           v-model="invoicing_details.company_name"/>
              <FieldsInput label="Invoicing email address" name="invoice_email" type="email" required
                           class="sm:col-span-2"
                           v-model="invoicing_details.invoice_email"/>
              <FieldsInput label="Address" class="sm:col-span-2" name="address" required
                           v-model="invoicing_details.address"/>
              <FieldsCountrySelect label="Country" name="country" required v-model="invoicing_details.country"/>
              <FieldsInput label="State / Province" name="state" required v-model="invoicing_details.state"/>
              <FieldsInput label="City" class="sm:col-span-2" name="city" required v-model="invoicing_details.city"/>
              <FieldsCheckBox label="Save invoicing details" name="save_invoicing_details"
                              v-model="invoicing_details.save_invoicing_details"/>
            </div>

          </div>
        </div>

        <!-- Order summary -->
        <div class="mt-10 lg:mt-0">
          <h2 class="text-lg font-medium text-gray-900 dark:text-white">Order summary</h2>

          <div class="mt-4 rounded-lg border border-gray-200 dark:border-zinc-700 bg-white dark:bg-zinc-800 shadow-sm">
            <h3 class="sr-only">Items in your cart</h3>
            <ul role="list" class="divide-y divide-gray-200 dark:divide-zinc-700">
              <CartItem v-for="item in cart" :key="item.id" :id="item.id" :package="item.package"
                        :product="item.package.product" :price="item.price" :coupon="item.coupon"/>
            </ul>

            <CartApplyCouponInput/>

            <dl class="space-y-6 border-t border-gray-200 dark:border-zinc-700 px-4 py-6 sm:px-6">
              <div class="flex items-center justify-between">
                <dt class="text-base font-medium dark:text-gray-100">Total</dt>
                <dd class="text-base font-medium text-gray-900 dark:text-white">{{ formatPrice(total) }}</dd>
              </div>
            </dl>
            <div class="border-t border-gray-200 dark:border-zinc-700 px-4 py-6 sm:px-6 flex flex-col gap-4">
              <button @click="submit" type="submit" :disabled="count === 0"
                      class="w-full inline-flex justify-center rounded-md border border-transparent bg-primary-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-50">
                <svg v-if="loading" class="animate-spin -ml-1 mr-3 mt-0.5 h-5 w-5 text-white"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Confirm order
              </button>

              <FieldsCheckBox
                  name="marketing_consent"
                  v-model="invoicing_details.marketing_consent"
                  label="MCModels would like to email you coupons, alerts on exciting products, and other goodies!"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

</template>

<style scoped>

</style>