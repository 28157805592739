import { default as cartX80nXatdidMeta } from "/home/ploi/mcmodels.net/pages/cart.vue?macro=true";
import { default as _91id_93_45_91slug_93cZZoIlWQZDMeta } from "/home/ploi/mcmodels.net/pages/categories/[id]-[slug].vue?macro=true";
import { default as forgot_45passwordAi44DehfUUMeta } from "/home/ploi/mcmodels.net/pages/forgot-password.vue?macro=true";
import { default as _91id_93_45_91slug_93H5tPLhsMfdMeta } from "/home/ploi/mcmodels.net/pages/guides/[id]-[slug].vue?macro=true";
import { default as indexNslMb3v8AGMeta } from "/home/ploi/mcmodels.net/pages/guides/index.vue?macro=true";
import { default as vendorhgoRiTi6FVMeta } from "/home/ploi/mcmodels.net/pages/guides/vendor.vue?macro=true";
import { default as indexm8v6x52UhqMeta } from "/home/ploi/mcmodels.net/pages/index.vue?macro=true";
import { default as aboutseH3yrDgtOMeta } from "/home/ploi/mcmodels.net/pages/information/about.vue?macro=true";
import { default as privacyR21JFCGsx2Meta } from "/home/ploi/mcmodels.net/pages/information/privacy.vue?macro=true";
import { default as termsTNlltQCXmIMeta } from "/home/ploi/mcmodels.net/pages/information/terms.vue?macro=true";
import { default as loginXfWUik1sK2Meta } from "/home/ploi/mcmodels.net/pages/login.vue?macro=true";
import { default as _91id_93_45_91slug_93T0rxq1HZ77Meta } from "/home/ploi/mcmodels.net/pages/news/[id]-[slug].vue?macro=true";
import { default as indexlAs5o1uuE6Meta } from "/home/ploi/mcmodels.net/pages/news/index.vue?macro=true";
import { default as _91token_93eayGxNDmU7Meta } from "/home/ploi/mcmodels.net/pages/password-reset/[token].vue?macro=true";
import { default as _91slug_93Sc3Y7MIaAJMeta } from "/home/ploi/mcmodels.net/pages/products/[id]/[slug].vue?macro=true";
import { default as indexApufgBwTjXMeta } from "/home/ploi/mcmodels.net/pages/products/index.vue?macro=true";
import { default as indexc0IaYG7n3FMeta } from "/home/ploi/mcmodels.net/pages/profile/index.vue?macro=true";
import { default as _91id_93F9Zc8NM03lMeta } from "/home/ploi/mcmodels.net/pages/profile/orders/[id].vue?macro=true";
import { default as indexRlriqpMXUjMeta } from "/home/ploi/mcmodels.net/pages/profile/orders/index.vue?macro=true";
import { default as securityl0gaPcFrD8Meta } from "/home/ploi/mcmodels.net/pages/profile/security.vue?macro=true";
import { default as registerNxy3uNVVuCMeta } from "/home/ploi/mcmodels.net/pages/register.vue?macro=true";
import { default as _91slug_93ruo53QEFyIMeta } from "/home/ploi/mcmodels.net/pages/vendors/[id]/[slug].vue?macro=true";
import { default as indexldzrAtVLtSMeta } from "/home/ploi/mcmodels.net/pages/vendors/index.vue?macro=true";
import { default as component_45stubGb03O5Id2LMeta } from "/home/ploi/mcmodels.net/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubGb03O5Id2L } from "/home/ploi/mcmodels.net/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "cart",
    path: "/cart",
    meta: cartX80nXatdidMeta || {},
    component: () => import("/home/ploi/mcmodels.net/pages/cart.vue")
  },
  {
    name: "categories-id-slug",
    path: "/categories/:id()-:slug()",
    component: () => import("/home/ploi/mcmodels.net/pages/categories/[id]-[slug].vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordAi44DehfUUMeta || {},
    component: () => import("/home/ploi/mcmodels.net/pages/forgot-password.vue")
  },
  {
    name: "guides-id-slug",
    path: "/guides/:id()-:slug()",
    component: () => import("/home/ploi/mcmodels.net/pages/guides/[id]-[slug].vue")
  },
  {
    name: "guides",
    path: "/guides",
    component: () => import("/home/ploi/mcmodels.net/pages/guides/index.vue")
  },
  {
    name: "guides-vendor",
    path: "/guides/vendor",
    component: () => import("/home/ploi/mcmodels.net/pages/guides/vendor.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ploi/mcmodels.net/pages/index.vue")
  },
  {
    name: "information-about",
    path: "/information/about",
    component: () => import("/home/ploi/mcmodels.net/pages/information/about.vue")
  },
  {
    name: "information-privacy",
    path: "/information/privacy",
    component: () => import("/home/ploi/mcmodels.net/pages/information/privacy.vue")
  },
  {
    name: "information-terms",
    path: "/information/terms",
    component: () => import("/home/ploi/mcmodels.net/pages/information/terms.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginXfWUik1sK2Meta || {},
    component: () => import("/home/ploi/mcmodels.net/pages/login.vue")
  },
  {
    name: "news-id-slug",
    path: "/news/:id()-:slug()",
    component: () => import("/home/ploi/mcmodels.net/pages/news/[id]-[slug].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/home/ploi/mcmodels.net/pages/news/index.vue")
  },
  {
    name: "password-reset-token",
    path: "/password-reset/:token()",
    meta: _91token_93eayGxNDmU7Meta || {},
    component: () => import("/home/ploi/mcmodels.net/pages/password-reset/[token].vue")
  },
  {
    name: "products-id-slug",
    path: "/products/:id()/:slug()",
    component: () => import("/home/ploi/mcmodels.net/pages/products/[id]/[slug].vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/home/ploi/mcmodels.net/pages/products/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexc0IaYG7n3FMeta || {},
    component: () => import("/home/ploi/mcmodels.net/pages/profile/index.vue")
  },
  {
    name: "profile-orders-id",
    path: "/profile/orders/:id()",
    component: () => import("/home/ploi/mcmodels.net/pages/profile/orders/[id].vue")
  },
  {
    name: "profile-orders",
    path: "/profile/orders",
    meta: indexRlriqpMXUjMeta || {},
    component: () => import("/home/ploi/mcmodels.net/pages/profile/orders/index.vue")
  },
  {
    name: "profile-security",
    path: "/profile/security",
    meta: securityl0gaPcFrD8Meta || {},
    component: () => import("/home/ploi/mcmodels.net/pages/profile/security.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerNxy3uNVVuCMeta || {},
    component: () => import("/home/ploi/mcmodels.net/pages/register.vue")
  },
  {
    name: "vendors-id-slug",
    path: "/vendors/:id()/:slug()",
    component: () => import("/home/ploi/mcmodels.net/pages/vendors/[id]/[slug].vue")
  },
  {
    name: "vendors",
    path: "/vendors",
    component: () => import("/home/ploi/mcmodels.net/pages/vendors/index.vue")
  },
  {
    name: component_45stubGb03O5Id2LMeta?.name,
    path: "/how-to-tutorials",
    component: component_45stubGb03O5Id2L
  },
  {
    name: component_45stubGb03O5Id2LMeta?.name,
    path: "/model-category",
    component: component_45stubGb03O5Id2L
  },
  {
    name: component_45stubGb03O5Id2LMeta?.name,
    path: "/shop",
    component: component_45stubGb03O5Id2L
  },
  {
    name: component_45stubGb03O5Id2LMeta?.name,
    path: "/how-to-tutorials/:pathMatch(.*)",
    component: component_45stubGb03O5Id2L
  },
  {
    name: component_45stubGb03O5Id2LMeta?.name,
    path: "/model-category/:pathMatch(.*)",
    component: component_45stubGb03O5Id2L
  },
  {
    name: component_45stubGb03O5Id2LMeta?.name,
    path: "/shop/:pathMatch(.*)",
    component: component_45stubGb03O5Id2L
  },
  {
    name: component_45stubGb03O5Id2LMeta?.name,
    path: "/author/:pathMatch(.*)",
    component: component_45stubGb03O5Id2L
  },
  {
    name: component_45stubGb03O5Id2LMeta?.name,
    path: "/model-tag",
    component: component_45stubGb03O5Id2L
  },
  {
    name: component_45stubGb03O5Id2LMeta?.name,
    path: "/about-us",
    component: component_45stubGb03O5Id2L
  }
]