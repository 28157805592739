<script lang="ts">
import {defineComponent} from 'vue'
import {useUserStore} from '~/stores/user'
import type {LoginResponse} from "~/types/responses";

export default defineComponent({
  name: "login",
  setup() {
    definePageMeta({
      middleware: ["guest"]
    })
  },
  methods: {
    async login() {
      this.loading = true
      $api('/auth/login', {
        method: 'POST',
        body: this.form
      }).then(async (response: LoginResponse) => {
        const userStore = useUserStore();
        userStore.setToken(response.token);
        userStore.setUser(response.user);
        navigateTo(useRoute().query.redirect_to ? useRoute().query.redirect_to : '/')
        this.loading = false;
      }).finally(() => {
        this.loading = false
      })
    }
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      loading: false,
    }
  },
})
</script>

<template>
  <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 ">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <NuxtImg format="webp" class="mx-auto h-10 w-auto" src="/img/logo_icon_red.png" alt="The MCModels pictorial logo mark"/>
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-gray-100">Sign in
        to your account</h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form class="space-y-6" @submit.prevent="login">

        <FieldsInput
            name="email"
            v-model="form.email"
            label="Email address"
            type="email"
            autocomplete="email"
            required/>

        <div>
          <FieldsInput
              name="password"
              v-model="form.password"
              label="Password"
              type="password"
              autocomplete="current-password"
              required/>

          <div class="text-sm mt-2">
            <NuxtLink href="/forgot-password"
                      class="font-semibold text-primary-600 hover:text-primary-500 dark:text-primary-500 dark:hover:text-primary-600">
              Forgot password?
            </NuxtLink>
          </div>
        </div>

        <div>
          <Button type="submit" class="w-full" :loading="loading">Sign in</Button>
        </div>
      </form>

      <AuthDiscordLoginButton/>

      <p class="mt-10 text-center text-sm text-gray-500">
        Don't have an account yet?
        {{ ' ' }}
        <NuxtLink href="/register"
                  class="font-semibold leading-6 text-primary-600 hover:text-primary-500 dark:text-primary-500 dark:hover:text-primary-600">
          Sign up for an account
        </NuxtLink>
      </p>
    </div>
  </div>
</template>

<style scoped>

</style>