<script lang="ts">
import {defineComponent} from 'vue'
import type {Order} from "~/types/models";
import Profile from "~/layouts/profile.vue";
import DownloadButton from "~/components/orders/DownloadButton.vue";
import {formatPrice} from "~/utils/formatPrice";
import {formatDateTime} from "~/utils/formatDateTime";
import SupportButton from "~/components/orders/SupportButton.vue";

definePageMeta({
  middleware: ["auth"],
})
export default defineComponent({
  name: "orders",
  methods: {formatDateTime, formatPrice},
  components: {SupportButton, DownloadButton, Profile},
  data() {
    return {
      orders: [] as Order[],
    }
  },
  mounted() {
    $api('/user/orders').then((response) => {
      this.orders = response.data
    })
  },
})
</script>

<template>
  <Profile>
    <div class="max-w-xl">
      <h1 class="text-2xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-3xl">Order history</h1>
      <p class="mt-1 text-sm text-gray-500">Check the status of recent orders, and download products. </p>
    </div>

    <section aria-labelledby="recent-heading" class="mt-7">
      <h2 id="recent-heading" class="sr-only">Recent orders</h2>

      <div class="space-y-7"> <!-- space-y-7 -->
        <div v-for="order in orders"  :key="order.uuid">
          <h3 class="sr-only">
            Order placed on <time :datetime="order.created_at">{{ order.created_at }}</time>
          </h3>

          <div class="rounded-lg bg-gray-50 dark:bg-zinc-800 px-4 py-6 sm:flex sm:items-center sm:justify-between sm:space-x-6 sm:px-6 lg:space-x-8">
            <dl class="flex-auto space-y-6 divide-y divide-gray-100 dark:divide-zinc-700 text-sm text-gray-600 sm:grid sm:grid-cols-3 sm:gap-x-6 sm:space-y-0 sm:divide-y-0 lg:w-[70%] lg:flex-none lg:gap-x-8">
              <div class="flex justify-between sm:block">
                <dt class="font-medium text-gray-900 dark:text-white">Date placed</dt>
                <dd class="sm:mt-1 dark:text-white">
                  <time :datetime="order.created_at">{{ formatDateTime(order.created_at) }}</time>
                </dd>
              </div>
              <div class="flex justify-between pt-6 sm:block sm:pt-0">
                <dt class="font-medium text-gray-900 dark:text-white">Order number</dt>
                <dd class="sm:mt-1 dark:text-white">{{ order.id }}</dd>
              </div>
              <div class="flex justify-between pt-6 font-medium text-gray-900 sm:block sm:pt-0 dark:text-white">
                <dt>Total amount</dt>
                <dd class="sm:mt-1">{{ formatPrice(order.total) }}</dd>
              </div>
            </dl>
            <NuxtLink :to="'/profile/orders/' + order.uuid" class="mt-6 flex w-full items-center justify-center rounded-md border border-gray-300 dark:border-zinc-700 bg-white dark:bg-zinc-900 dark:text-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:w-auto">
              View order
              <span class="sr-only">for order {{ order.id }}</span>
            </NuxtLink>
          </div>

          <table class="mt-2 w-full text-gray-500 dark:text-white sm:mt-4">
            <caption class="sr-only">
              Products
            </caption>
            <thead class="sr-only text-left text-sm text-gray-500 dark:text-white sm:not-sr-only">
            <tr>
              <th scope="col" class="py-3 pr-8 font-normal sm:w-2/5 lg:w-1/3">Product</th>
              <th scope="col" class="hidden w-1/5 py-3 pr-8 font-normal sm:table-cell">Price</th>
              <th scope="col" class="hidden py-3 pr-8 font-normal sm:table-cell">Vendor</th>
              <th scope="col" class="w-0 py-3 text-right font-normal"></th>
            </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 dark:divide-zinc-700  border-b border-gray-200 dark:border-zinc-700  text-sm sm:border-t">
            <tr v-for="orderPackage in order.packages" :key="orderPackage.id">
              <td class="py-4 pr-8">
                <div class="flex items-center">
                <NuxtImg format="webp" :src="orderPackage.product?.thumbnail?.full_url" :alt="orderPackage.product?.thumbnail?.alt_text" class="mr-6 h-8 w-8 rounded object-cover object-center" />
                  <NuxtLink :to="'/products/' + orderPackage.product?.id + '/' + orderPackage.product?.slug" >
                    <div>
                      <div class="font-medium text-gray-900 dark:text-white">{{ orderPackage.name }}</div>
                      <div class="mt-1 sm:hidden">{{ formatPrice(orderPackage.total) }}</div>
                    </div>
                  </NuxtLink>
                </div>
              </td>
              <td class="hidden py-6 pr-8 sm:table-cell">
                <span class="line-through" v-if="orderPackage.price !== orderPackage.total">{{ formatPrice(orderPackage.price) }}</span>
                {{ formatPrice(orderPackage.total) }}
              </td>
              <td class="hidden py-6 pr-8 sm:table-cell">{{ orderPackage.product.store.name }}</td>
              <td class="whitespace-nowrap py-6 text-right font-medium">
                <DownloadButton v-if="order.status === 'paid'" :orderPackage="orderPackage"/>
                <div class="mt-3">
                  <SupportButton v-if="order.status === 'paid'" :orderPackage="orderPackage" :order="order"/>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </Profile>
</template>

<style scoped>
</style>