<script lang="ts">
import {defineComponent} from 'vue'
import { useUserStore } from '~/stores/user'
import { useNotificationStore } from '~/stores/util/notifications'
import type {RegisterResponse} from "~/types/responses"

export default defineComponent({
  name: "register",
  setup() {
    definePageMeta({
      middleware: ["guest"]
    })
  },
  methods: {
    async login() {
      this.loading = true
      $api('/auth/register', {
        method: 'POST',
        body: this.form
      }).then(async (response: RegisterResponse) => {
        const userStore = useUserStore();
        await userStore.setToken(response.token);

        navigateTo('/')
      }).finally(() => {
        this.loading = false
      })
    }
  },
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        marketing_consent: false,
      },
      loading: false,
    }
  },
})
</script>

<template>
  <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <NuxtImg format="webp" class="mx-auto h-10 w-auto" src="/img/logo_icon_red.png" alt="The MCModels pictorial logo mark" />
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-gray-100">Create an new account</h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form class="space-y-6" @submit.prevent="login">

        <div class="grid sm:grid-cols-2 gap-y-4">
          <FieldsInput
              name="first_name"
              v-model="form.first_name"
              label="First name"
              type="text"
              autocomplete="first_name"
              required/>

          <FieldsInput
              name="last_name"
              v-model="form.last_name"
              label="Last name"
              type="text"
              autocomplete="last_name"
              required/>
        </div>

        <FieldsInput
            name="email"
            v-model="form.email"
            label="Email address"
            type="email"
            autocomplete="email"
            required/>

        <FieldsPassword
            name="password"
            v-model="form.password"
            label="Password"
            required/>

        <FieldsPassword
            name="password_confirmation"
            v-model="form.password_confirmation"
            label="Confirm password"
            required/>

        <FieldsCheckBox
            name="marketing_consent"
            v-model="form.marketing_consent"
            label="MCModels would like to email you coupons, alerts on exciting products, and other goodies!"
        />

        <div>
          <Button type="submit" class="w-full" :loading="loading">Sign in</Button>
        </div>
      </form>

      <p class="mt-10 text-center text-sm text-gray-500">
        Already have an account?
        {{ ' ' }}
        <NuxtLink href="/login" class="font-semibold leading-6 text-primary-600 hover:text-primary-500 dark:text-primary-500 dark:hover:text-primary-600">Log into your account</NuxtLink>
      </p>
    </div>
  </div>
</template>

<style scoped>

</style>