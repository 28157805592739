<script lang="ts">

import type {UserResponse} from "~/types/responses";
import Profile from "~/layouts/profile.vue";

definePageMeta({
  middleware: ["auth"],
})

import {defineComponent} from 'vue'

export default defineComponent({
  name: "security",
  components: {Profile},
  data() {
    return {
      loading: false,
      passwords: {
        current_password: '',
        new_password: '',
        new_password_confirmation: '',
      },
    }
  },
  methods: {
    updatePassword() {
      this.loading = true

      $api('/user', {
        method: 'PUT',
        body: this.passwords
      }).then(async (response: UserResponse) => {
        this.loading = false
        this.passwords.current_password = ''
        this.passwords.new_password = ''
        this.passwords.new_password_confirmation = ''
      }).catch(() => {
        this.loading = false
      })
    }
  }
})
</script>

<template>
  <Profile>
    <div class="grid gap-8">
      <form @submit.prevent="updatePassword()">
        <Card title="Your profile">
          <div class="grid gap-y-4">
            <FieldsPassword
                name="new_password"
                v-model="passwords.new_password"
                label="New password"
                placeholder="New password"
            />
            <FieldsPassword
                name="new_password_confirmation"
                v-model="passwords.new_password_confirmation"
                label="Confirm new password"
                placeholder="Confirm new password"
            />
            <FieldsPassword
                name="current_password"
                v-model="passwords.current_password"
                label="Current password"
                placeholder="Current password"/>
          </div>
          <template v-slot:footer>
            <div class="flex justify-end">
              <Button :loading="loading" type="submit">Save</Button>
            </div>
          </template>
        </Card>
      </form>
    </div>
  </Profile>
</template>

<style scoped>

</style>